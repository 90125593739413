import React from "react"
import PropTypes from "prop-types"
import { FeaturesColumn } from "./common"
import Heading from "@components/elements/heading"
import Grid from "@components/elements/grid"
import Flex from "@components/elements/flex"
import Html from "@components/html"
import Span from "@components/elements/span"
import theme from "@styles/theme"
import Div from "@components/elements/div"
import Image from "@components/image"

function backgroundGradient(variation) {
  switch (variation) {
    default:
      return
    case 0:
      return `linear-gradient(180deg, ${theme.color.pmGradient100} 0%, ${theme.color.pmGradient200} 100%)`
    case 1:
      return `linear-gradient(180deg, ${theme.color.pmGradient200} 0%, ${theme.color.pmGradient300} 100%)`
    case 2:
      return `linear-gradient(180deg, ${theme.color.pmGradient300} 0%, ${theme.color.pmGradient400} 100%)`
    case 3:
      return `linear-gradient(180deg, ${theme.color.pmGradient400} 0%, ${theme.color.pmGradient500} 100%)`
    case 4:
      return `linear-gradient(180deg, ${theme.color.pmGradient500} 0%, ${theme.color.pmGradient600} 100%)`
  }
}

function textColour(variation) {
  switch (variation) {
    default:
      return
    case 0:
      return theme.color.pmGradient100
    case 1:
      return theme.color.pmGradient200
    case 2:
      return theme.color.pmGradient300
    case 3:
      return theme.color.pmGradient400
    case 4:
      return theme.color.pmGradient500
  }
}

export const FeaturesPanels = ({ panels, offset = 0 }) => {
  return (
    <Flex gap="12rem" gapMd="6rem" gapSm="6rem">
      {panels.map((panel, index) => {
        const variation = index + offset
        const isLeft = (index + offset) % 2 == 1 // Alternate left/right
        return (
          <Grid
            gap="4rem"
            columns={"1fr 1fr"}
            columnsMd="1fr"
            columnsSm="1fr"
            key={`panel-${index}`}
          >
            <Div
              height="min-content"
              borderRadius="2rem"
              padding="4rem 2rem"
              background={backgroundGradient(variation)}
            >
              {panel.image && (
                <Image
                  file={panel.image}
                  alt={`${panel.title} ${panel.title_highlight}`}
                />
              )}
            </Div>
            <FeaturesColumn reverse={isLeft}>
              {panel.title ? (
                <Heading
                  lineHeight="125%"
                  margin="0 0 2rem"
                  as="h2"
                  fontSize="3rem"
                >
                  <span>{panel.title}</span>&nbsp;
                  <Span color={textColour(variation, theme)}>
                    {panel.title_highlight}
                  </Span>
                </Heading>
              ) : (
                <Heading margin="0 0 2rem" as="h2">
                  <Span color={textColour(variation, theme)}>
                    {panel.title_highlight}
                  </Span>
                </Heading>
              )}
              <Grid gap="1rem" fontSize="1.3rem">
                <Html inherit parseElements html={panel.description} />
              </Grid>
            </FeaturesColumn>
          </Grid>
        )
      })}
    </Flex>
  )
}

FeaturesPanels.propTypes = {
  panels: PropTypes.array.isRequired,
  offset: PropTypes.number,
}
