import React from "react"
import styled, { css, useTheme } from "styled-components"
import PropTypes from "prop-types"
import media from "@styles/media"
import Button from "@components/elements/button"
import GradientText from "@components/common/gradient-text"

const CtaContainer = styled.div`
  width: 800px;
  margin: 0 auto;
  height: 330px;
  padding: 2rem;
  border-radius: 2rem;
  background: linear-gradient(
    80.63deg,
    #fbfbfb 0%,
    #c9bcf6 44.62%,
    #ba93df 66.41%,
    #e59ebf 99.62%
  );
  position: relative;
  display: flex;

  ${props =>
    props.endsPage &&
    css`
      margin-bottom: 8rem;
    `}

  ${media.md`
    display: none;
  `}

  h3 {
    font-size: 2rem;
    line-height: 1.25;
    margin: 1rem 0;
    letter-spacing: -0.03rem;
  }
`

const Header = styled.h2`
  font-size: 3rem;
  line-height: 1.2;
  width: "max-content";
  text-align: left;
`

const Artwork = styled.div`
  position: absolute;
  right: -35px;
  top: 0;
  svg {
    margin-top: -35px;
    height: 400px;
  }
`

const CtaCircle = () => {
  return (
    <Artwork>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 589 621">
        <circle cx="294.1" cy="326.9" r="294.1" fill="#fff" fillOpacity=".2" />
        <circle cx="294.1" cy="326.9" r="196.1" fill="#fff" fillOpacity=".4" />
        <g filter="url(#a)">
          <circle cx="533.2" cy="159.9" r="29.4" fill="#fff" />
        </g>
        <path
          fill="#635BFF"
          d="M534 151.5c-2 0-3 .6-3 2 0 3.9 13.3 2 13.4 12.5 0 6.3-5 10-12.4 10-3 0-6.3-.7-9.6-2v-8.4a22 22 0 0 0 9.6 2.8c2 0 3.4-.6 3.4-2.2 0-4.2-13.4-2.6-13.4-12.3 0-6.3 4.8-10 12-10 2.8 0 5.7.4 8.7 1.6v8.3c-2.7-1.5-6.1-2.3-8.8-2.3Z"
        />
        <g filter="url(#b)">
          <circle cx="408.7" cy="479.4" r="40.1" fill="#fff" />
        </g>
        <path
          fill="#F9AB00"
          d="M416.7 463.1v32.1c0 3.6 2.5 5.6 5.1 5.6 2.5 0 5.1-1.7 5.1-5.6v-31.8c0-3.3-2.4-5.4-5-5.4a5.2 5.2 0 0 0-5.2 5.1Z"
        />
        <path
          fill="#E37400"
          d="M403.4 479.4v15.8c0 3.6 2.5 5.6 5 5.6s5.2-1.7 5.2-5.6v-15.5c0-3.3-2.4-5.4-5.1-5.4a5.2 5.2 0 0 0-5.1 5.1ZM395.1 500.8a5.1 5.1 0 1 0 0-10.2 5.1 5.1 0 0 0 0 10.2Z"
        />
        <g filter="url(#c)">
          <circle cx="106.3" cy="259.8" r="40.1" fill="#fff" />
        </g>
        <path
          fill="#00B9EA"
          fillRule="evenodd"
          d="M106.3 238.5a21.4 21.4 0 1 0 0 42.7 21.4 21.4 0 0 0 0-42.7Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M108.4 259.9a1.2 1.2 0 0 0 .2-1 4.8 4.8 0 0 0-4.8-4 4.8 4.8 0 0 0-4.7 4.9c0 .4 0 .8.2 1.2a4.9 4.9 0 0 0 4.6 3.6l1.2-.1a4.7 4.7 0 0 0 2.6-1.8.8.8 0 0 0 .2-.6.7.7 0 0 0-.2-.5.7.7 0 0 0-.5-.2c-.1 0-.3 0-.5.3l-.3.3-.9.8c-.5.3-1 .4-1.6.4a3.5 3.5 0 0 1-3.3-2.8h6.7c.5 0 .8-.2 1-.5Zm-7.8-1a3.4 3.4 0 0 1 6.6 0h-6.6Zm18.8.8a1.2 1.2 0 0 1-1.5 1.2 1.2 1.2 0 1 1 1.5-1.2Zm-6.4-3.3h-.2c-1.4.2-1.6.8-1.6 2.5v5a.7.7 0 0 1-.4.6.7.7 0 0 1-.9-.4v-8.3a.7.7 0 0 1 .5-.7.7.7 0 0 1 .8.4c.5-.3 1-.5 1.6-.5h.2a.7.7 0 0 1 .7.7c0 .4-.3.7-.7.7Zm-14 7a.7.7 0 0 1 .3.5.7.7 0 0 1-1 .6l-.2-.1-3.6-3.6-3.6 3.6a.7.7 0 0 1-.8.1.7.7 0 0 1-.4-.6.7.7 0 0 1 .2-.5l3.6-3.6-3.6-3.7a.7.7 0 0 1 0-1l.5-.2a.7.7 0 0 1 .5.2l3.6 3.7L98 255a.7.7 0 0 1 1 0 .7.7 0 0 1 .1.8l-.1.2-3.6 3.7 3.6 3.6Zm19.2-8.5a4.8 4.8 0 0 0-4.8 4.8c0 2.7 2.1 4.9 4.8 4.9 2.6 0 4.8-2.2 4.8-4.9s-2.2-4.8-4.8-4.8Zm0 8.2a3.4 3.4 0 0 1-3.4-3.4 3.4 3.4 0 1 1 6.7 0c0 1.9-1.5 3.4-3.3 3.4Z"
          clipRule="evenodd"
        />
        <g filter="url(#d)">
          <circle cx="66.3" cy="514.1" r="18.7" fill="#fff" />
        </g>
        <path
          fill="#2CA01C"
          d="M65.5 527a12.5 12.5 0 1 0 0-25 12.5 12.5 0 0 0 0 25Z"
        />
        <path
          fill="#fff"
          d="M56.5 514.5a4.9 4.9 0 0 0 4.8 4.9h.7v-1.8h-.7a3 3 0 0 1 0-6.2H63v9.5a1.8 1.8 0 0 0 1.8 1.8v-13h-3.5a4.9 4.9 0 0 0-4.8 4.8Zm13.2-4.9H69v1.8h.7a3 3 0 0 1 0 6.2H68V508a1.8 1.8 0 0 0-1.8-1.8v13h3.5a4.9 4.9 0 0 0 0-9.7Z"
        />
        <g filter="url(#e)">
          <circle cx="195.5" cy="53.1" r="32.1" fill="#fff" />
        </g>
        <path
          fill="#FF7A59"
          d="M204.2 47.3v-4a3 3 0 0 0 1.7-2.8 3 3 0 0 0-3-3.1h-.1a3 3 0 0 0-3.1 3v.1a3 3 0 0 0 1.8 2.8v4c-1.6.2-3 .9-4.2 1.8l-11-8.5a3.4 3.4 0 0 0-4-4.3 3.5 3.5 0 0 0 .7 6.9c.6 0 1.2-.2 1.7-.5l10.8 8.4a8.8 8.8 0 0 0 .1 9.9l-3.3 3.3a3 3 0 1 0 2 1.9l3.2-3.3a8.8 8.8 0 1 0 6.7-15.6Zm-1.4 13.2a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9"
        />
        <g filter="url(#f)">
          <circle cx="294.4" cy="326.6" r="66.8" fill="#fff" />
        </g>
        <path
          fill="#4B57C5"
          fillRule="evenodd"
          d="m321.8 345-9.3-24.2-6-15.5a5.4 5.4 0 0 0-10 0l-6.9 17.8-2-5.6a5.4 5.4 0 0 0-10 0L267 345.2a5.2 5.2 0 0 0 .6 4.9c1 1.5 2.7 2.3 4.5 2.3h44.6a5.4 5.4 0 0 0 5-7.3Z"
          clipRule="evenodd"
        />
        <path
          fill="#404BA8"
          fillRule="evenodd"
          d="M300.7 352.3h-28.5a5.4 5.4 0 0 1-5.1-3.5l26.2-16.1 7.4 19.6Z"
          clipRule="evenodd"
        />
        <path
          fill="#4B57C5"
          fillRule="evenodd"
          d="M321.3 350a5.4 5.4 0 0 1-4.5 2.3h-16l-7.5-19.6 19.2-11.9 9.3 24.2c.7 1.7.4 3.5-.5 5Z"
          clipRule="evenodd"
        />
        <path
          fill="#6070FC"
          fillRule="evenodd"
          d="m312.5 320.8-19.2 11.9-3.7-9.6 6.9-17.8a5.4 5.4 0 0 1 10 0l6 15.5Z"
          clipRule="evenodd"
        />
        <path
          fill="#4B57C5"
          fillRule="evenodd"
          d="M293.3 332.7 267 348.8a5.2 5.2 0 0 1 0-3.7l10.4-27.5a5.4 5.4 0 0 1 10-.1l2 5.5 3.8 9.7Z"
          clipRule="evenodd"
        />
        <defs>
          <filter
            id="a"
            width="108.8"
            height="108.8"
            x="478.8"
            y="109.5"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_94_8986"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_94_8986"
              result="shape"
            />
          </filter>
          <filter
            id="b"
            width="130.1"
            height="130.1"
            x="343.7"
            y="418.3"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_94_8986"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_94_8986"
              result="shape"
            />
          </filter>
          <filter
            id="c"
            width="130.1"
            height="130.1"
            x="41.3"
            y="198.8"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_94_8986"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_94_8986"
              result="shape"
            />
          </filter>
          <filter
            id="d"
            width="87.4"
            height="87.4"
            x="22.6"
            y="474.4"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_94_8986"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_94_8986"
              result="shape"
            />
          </filter>
          <filter
            id="e"
            width="114.1"
            height="114.1"
            x="138.5"
            y="0"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_94_8986"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_94_8986"
              result="shape"
            />
          </filter>
          <filter
            id="f"
            width="183.6"
            height="183.6"
            x="202.6"
            y="238.8"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_94_8986"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_94_8986"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Artwork>
  )
}

const Left = styled.div`
  width: "min-content";
  gap: 2rem;
  align-self: center;
`

const FeaturesEndOfPageCTA = ({ endsPage }) => {
  const { color } = useTheme()
  return (
    <CtaContainer endsPage={endsPage}>
      <Left>
        <Header>
          <span>Turn your data</span>
          <br />
          <GradientText gradient="linear-gradient(90deg, #635bff 0%, #995bff 100%)">
            into insights
          </GradientText>
          <br />
        </Header>
        <Button.PowerMetrics noticeColor={color.indigo600} data-cta-location="eop" />
      </Left>
      <CtaCircle />
    </CtaContainer>
  )
}

FeaturesEndOfPageCTA.propTypes = {
  endsPage: PropTypes.bool,
}

export default FeaturesEndOfPageCTA
