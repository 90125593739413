import styled, { css } from "styled-components"
import media from "@styles/media"

export const FeaturesColumn = styled.div`
  ul {
    margin-bottom: 0;
    li:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  h2 {
    max-width: auto;
    ${media.md_up`
            max-width: 22ch;
        `}
  }

  ${media.md_up`
        ${props =>
          props.reverse &&
          css`
            order: -1;
          `}
    `}

  ${media.md`
        ${props =>
          props.maxWidth &&
          css`
            max-width: ${props => props.maxWidth};
            margin: 0 auto;
          `}
    `}
`

export const HeroGradient = styled.img`
  position: absolute;
  top: -500px;
  left: -460px;
  width: 2000px;
  max-width: 2000px;
  max-height: 1000px;
  z-index: -1;
  height: auto;

  ${media.sm`
        top: -400px;
    `}
`

export const HeroImage = styled.div`
  display: flex;

  ${media.md_up`
        position: absolute;
        transform: translate(0, -50%);
        .klipfolio-image-wrapper {
            width: 820px;
        }
    `}

  .klipfolio-image-wrapper {
    height: max-content;
    align-self: center;
  }
`

export const Hero = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 3fr 4fr;
  gap: 2rem;
  margin: auto;
  align-items: center;

  ${media.md_up`
        min-height: 400px;
    `}

  ${media.md`
        grid-template-columns: 1fr;

        ${FeaturesColumn} {
            align-items: center;
            display: flex;
            flex-flow: column;
        }

        ${HeroGradient} {
            top: -300px;
        }
    `}
`

export const Cta = styled.div`
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 12rem;

  ${media.sm`
    margin-bottom: 4rem;
  `}
`
