import React from "react"
import styled from "styled-components"
import media from "@styles/media"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Grid from "@components/elements/grid"
import Html from "@components/html"
import Flex from "@components/elements/flex"
import Span from "@components/elements/span"
import theme from "@styles/theme"
import Image from "@components/image"

const IconHeader = styled.div`
  max-width: 700px;
  margin: 0 auto;

  ${media.md`
        margin: 0;
    `}

  h2:only-child {
    width: max-content;
    margin: 0 auto;
  }
`

const IconImage = styled.div`
  height: fit-content;
  padding: 4rem 2rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  background: ${({ variation, length, theme }) =>
    handleIconVariation(variation, length, theme)};
  img {
    margin: 0;
    align-self: center;
  }
`

const handleIconVariation = (variation, length, theme) => {
  if (length === 4) {
    switch (variation) {
      case 0:
        return `linear-gradient(150deg, ${theme.color.pmGradient100} 0%, ${theme.color.pmGradient200} 100%)`
      case 1:
        return `linear-gradient(150deg, ${theme.color.pmGradient200} 0%, ${theme.color.pmGradient300} 100%)`
      case 2:
        return `linear-gradient(150deg, ${theme.color.pmGradient300} 0%, ${theme.color.pmGradient400} 100%)`
      case 3:
        return `linear-gradient(150.62deg, ${theme.color.pmGradient400} 0%, ${theme.color.pmGradient500} 100%)`
      default:
        return `linear-gradient(150deg, ${theme.color.pmGradient100} 0%, ${theme.color.pmGradient200} 100%)`
    }
  } else {
    switch (variation) {
      case 0:
        return `linear-gradient(150deg, ${theme.color.pmGradient100} 0%, ${theme.color.pmGradient200} 100%)`
      case 1:
        return `linear-gradient(150deg, ${theme.color.pmGradient200} 0%, ${theme.color.pmGradient300} 100%)`
      case 2:
        return `linear-gradient(150deg, ${theme.color.pmGradient300} 0%, ${theme.color.pmGradient400} 100%)`
      case 3:
        return `linear-gradient(150deg, ${theme.color.pmGradient400} 0%, ${theme.color.pmGradient500} 100%)`
      case 4:
        return `linear-gradient(150deg, ${theme.color.pmGradient500} 0%, ${theme.color.pmGradient550} 100%)`
      case 5:
        return `linear-gradient(150deg, #CD5CEC 0%, ${theme.color.pmGradient600} 100%)`
      default:
        return `linear-gradient(150deg, ${theme.color.pmGradient100} 0%, ${theme.color.pmGradient200} 100%)`
    }
  }
}

const handleIconsVariation = length => {
  switch (length) {
    case 0:
    case 1:
    default:
      return `1fr`
    case 2:
    case 4:
      return `repeat(2, 1fr)`
    case 3:
    case 5:
    case 6:
      return `repeat(3, 1fr)`
  }
}

export const FeaturesIcons = ({ icons, header, highlight, description }) => {
  return (
    <div>
      {(header || highlight) && (
        <IconHeader>
          <Heading
            as="h2"
            fontSize="3.4rem"
            lineHeight="120%"
            margin="0 0 2rem"
          >
            {header && (
              <>
                <span>{header}</span>
                <br />
              </>
            )}
            <Span color={theme.color.pmGradient100}>{highlight}</Span>
          </Heading>
          {description && (
            <Flex gap="1rem">
              <Html parseElements html={description} />
            </Flex>
          )}
        </IconHeader>
      )}

      <Grid
        gap="3rem"
        columns={handleIconsVariation(icons.length)}
        margin="4rem 0 0"
        columnsMd="1fr"
        columnsSm="1fr"
      >
        {icons.map((icon, index) => {
          return (
            <div key={`icon-${icon.title}-${index}`}>
              <IconImage variation={index}>
                <Image
                  file={icon.icon}
                  height={150}
                  width={150}
                  objectFit="scale-down"
                  alt={`${icon.title} Icon`}
                />
              </IconImage>
              <Grid fontSize="1.2rem" margin="2rem 0 0" gap="2rem">
                <Heading as="h4">{icon.title}</Heading>
                <Html parseElements html={icon.description} />
              </Grid>
            </div>
          )
        })}
      </Grid>
    </div>
  )
}

FeaturesIcons.propTypes = {
  icons: PropTypes.array.isRequired,
  header: PropTypes.string,
  highlight: PropTypes.string,
  description: PropTypes.string,
}
